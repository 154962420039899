<template>
  <div 
    class="main-wrapper draggable-bounds" 
    @contextmenu.prevent.self=""
  >
    <div style="display: none">
      <img
        src="@/assets/event/full.gif"
        width="1"
        height="1"
        border="0"
        alt="Full bg"
      >
    </div>

    <div class="content-loaded">
      <div class="background-wrapper">
        <div class="global-item flex fix-width no-drag">
          <div class="loader-intro">
            <div class="sidebar" />
            <div class="main-content">
              <img
                src="@/assets/event/logo.png"
                class="logo flex-none"
              >
              <p 
                v-if="error"
                class="flex-none error absolute top-32 text-center px-10"
              >
                {{ error }}
              </p>
              <div class="">
                <div v-if="!eventId || eventId.length === 0">
                  <div class="intro-text space-y-0 pb-0">
                    <p>
                      Come back later for future Poolsuite events!
                    </p>
                  </div>
                </div>
                <div v-else-if="loading">
                  <div class="intro-text space-y-0 pb-0">
                    <p>
                      Loading
                      <span class="flashing-dots">
                        <span>.</span><span>.</span><span>.</span>
                      </span>
                    </p>
                  </div>
                </div>
                <div 
                  v-else-if="pass && pass.pendingExecMembership" 
                  class="my-14"
                >
                  <div class="intro-text">
                    <p>
                      <strong>{{ pass.event.title }}</strong>
                      <br>
                      {{ pass.event.location }}
                    </p>
                    <p class="my-4">
                      Your ticket requires that you verify your Executive Membership.
                      Click below to connect your wallet.
                    </p>
                    <p>
                      <button
                        class="button"
                        @click="connectWallet"
                      >
                        Connect Wallet
                      </button>
                    </p>
                    <p>
                      <strong>Note:</strong>
                      You only need to connect your wallet <strong>ONCE</strong> anytime before 
                      the event. You will not need to connect at the event.
                    </p>
                  </div>
                </div>
                <div v-else-if="pass && !pass.pendingExecMembership">
                  <div class="intro-text">
                    <p>
                      <strong>{{ pass.event.title }}</strong>
                      <br>
                      {{ pass.event.location }}
                    </p>
                  </div>
                  <div
                    class=" bg-white rounded-lg"
                  >
                    <VueQR
                      class="mx-4 mt-2"
                      :size="180"
                      :value="pass.code"
                      :foreground="pass.claimed ? '#DDD' : '#000'"
                    />
                  </div>
                  <div
                    v-if="pass.claimed"
                    class="intro-text"
                  >
                    <p>
                      <strong>This ticket has been claimed.</strong>
                    </p>
                  </div>
                  <div
                    v-else
                    class="intro-text"
                    style="padding: 0"
                  >
                    <p>
                      <strong>ADMIT ONE</strong>
                      <br>
                      <span v-if="pass.guests > 0">
                        + {{ pass.guests }} guest{{ pass.guests != 1 ? 's' : '' }}
                      </span>

                      <br><br>
                      Please note this QR code changes periodically.
                      <br>
                      <strong>A screenshot will not work for entry</strong>.
                    </p>
                  </div>
                </div>
                <div v-else>
                  <div class="intro-text space-y-4 pb-6 mx-6">
                    <p>
                      <strong>
                        <span v-if="!requestedPass">Claim your tickets!</span>
                        <span v-else>Thanks!</span>
                      </strong>
                    </p>
                    <p>
                      <span v-if="!requestedPass">
                        Have a ticket to one of our upcoming parties? Enter your e-mail address
                        here to receive a one-time link.
                      </span>
                      <span v-else>
                        If you have a ticket to an upcoming party, it'll arrive in your email box shortly.
                      </span>
                    </p>
                    <p
                      v-if="!requestedPass"
                      class="px-0 mx-0 w-72"
                    >
                      <input
                        v-model="email"
                        type="email"
                        placeholder="Your email address"
                        class="text-center px-0 mx-0"
                      >
                    </p>
                    <button
                      v-if="!requestedPass"
                      :disabled="disableButton"
                      class="button"
                      @click="getPass"
                    >
                      {{ getYourTicketsText }}
                    </button>
                  </div> 
                </div>
                <p class="flex-none copyright">
                  Poolsuite Leisure Corporation © Miami, FL
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { NFTConnect } from "@poolside-fm/nft-connect";
import VueQR from "qrcode.vue";
import Fetch from "fetch-retry";
const fetchRetry = Fetch(fetch);

export default {
  name: "App",
  components: {
    VueQR,
  },
  data: function () {
    return {
      loading: true,
      pass: false,
      email: "",
      requestedPass: false,
      error: false,
      nftConnect: false,
      connectText: "Connect wallet",
      loginText: "Sign message",
      getYourTicketsText: "Get your ticket",
      lastEmoji: false,
      address: false,
      eventId: false,
      timer: false,
    };
  },
  computed: {
    disableButton() {
      return !String(this.email)
        .toLowerCase()
        .match(/^\S+@\S+\.\S+$/);
    },
  },
  created() {
    this.nftConnect = new NFTConnect({
      rpcUrl:
        "https://eth-mainnet.alchemyapi.io/v2/i3eQYcfb35LzmEI6ASGn761GJKsOlfvH",
      appName: "Poolsuite Events",
      appDescription: "Connect your wallet to join the party!",
      serverHost: process.env.VUE_APP_POOLSUITE_HOST,
      serverAPIKey: process.env.VUE_APP_POOLSUITE_API_KEY,
    });
  },
  async mounted() {
    document.documentElement.style.setProperty(
      "--app-height",
      `${window.innerHeight}px`
    );

    window.addEventListener("resize", () => {
      document.documentElement.style.setProperty(
        "--app-height",
        `${window.innerHeight}px`
      );
    });

    const navURL = new URL(window.location.href);
    this.eventId = navURL.pathname.slice(1);
    if(this.eventId && this.eventId.length > 0) {
      // Check to see if we're coming in from a one-time link
      // If so, login with that link
      let urlParams = new URLSearchParams(navURL.search);
      if (urlParams.has("ps")) {
        try {
          const response = await this.apiFetch(
            "/authentication/link/login?" + urlParams.toString()
          );
          // If successful response, ditch all the URL params
          // TODO: Use router to switch back to base URL
          window.location.href = `/${this.eventId}`;
        } catch (err) {
          console.log(err);
        }
      }

      // Load the pass every 30 seconds
      await this.reloadPass(); 
      this.timer = setInterval(async () => {
        await this.reloadPass();
      }, 30000);
    }
      

    this.loading = false;
  },
  unmounted() {
    if(this.timer) {
      clearInterval(this.timer);
    }
  },
  methods: {
    async reloadPass() {
      // If we're logged in, we can check for passes
      const loggedInCookie = this.$cookies.get("ps");
      if(loggedInCookie) {
        const passes = await this.apiFetch(
          `/passes?event=${this.eventId}`,
        );
        if(passes && passes.length > 0) {
          this.pass = passes[0];
        }
      }
    },
    // TODO: Should def abstract this as a library
    //
    // This detects a 401 (access token may have expired) and
    // tries to refresh the token first. If that doesn't work,
    // it bails.
    async apiFetch(path, options = {}) {
      const url = `${process.env.VUE_APP_POOLSUITE_HOST}${path}`;
      // Setup options we want to send on every request
      const extraOptions = {
        credentials: "include",
        headers: {
          "Content-Type": "application/json",
          "X-Poolsuite-API": process.env.VUE_APP_POOLSUITE_API_KEY,
        },
      };
      // Setup a function to run the request
      const runRequest = () => {
        return fetch(url, {
          ...options,
          ...extraOptions,
        });
      };

      // Setup a function to refresh the token
      const refreshAccessToken = () => {
        return fetch(
          `${process.env.VUE_APP_POOLSUITE_HOST}/authentication/refresh`,
          extraOptions
        );
      };

      // First check if we have ever logged in
      const loggedInCookie = this.$cookies.get("ps");
      const loggedInExpiry = loggedInCookie && parseInt(loggedInCookie);
      if (loggedInExpiry) {
        // First check if our access has expired and refresh opportunistically
        if (Date.now() > loggedInExpiry) {
          await refreshAccessToken();
        }
      }

      // First just try running the request (duh)
      const response = await runRequest();
      if (response.ok) {
        // If we got a good response, return the JSON
        return await response.json();
      } else if (response.status === 401 && this.$cookies.isKey("ps")) {
        // If we got a 401 unauthorized, and we have logged in at least once
        // (aka have a refresh token), try once to refresh
        const refresh = await refreshAccessToken();
        if (refresh.ok) {
          // If we got a response, we're good to retry the initial request now
          const newResponse = await runRequest();
          if (newResponse.ok) {
            // If we got a good response, return the JSON
            return await newResponse.json();
          }
        }
      }
      // If we couldn't handle the request, just dump
      throw new Error();
    },
    async getPass() {
      this.getYourTicketsText = "One moment...";
      // Submit a request for one-time login to the server
      await this.apiFetch("/authentication/link/login", {
        method: "POST",
        body: JSON.stringify({ email: this.email }),
      });
      this.requestedPass = true;
      this.getYourTicketsText = "Get your ticket";
    },
    async connectWallet() {
      this.error = false;
      try {
        this.connectText = "Connecting...";
        const addressFound = await this.nftConnect.connectWallet();
        if (addressFound) {
          this.address = addressFound;
          this.updateWalletForUser();
        }
      } catch (err) {
        this.error = err.message;
      } finally {
        this.connectText = "Connect wallet";
      }
    },
    async updateWalletForUser() {
      this.error = false;
      try {
        this.walletStatusText = "Generating signature...";
        await this.nftConnect.verifyMembershipWithSignedMessage(
          "We're looking forward to seeing you at the party! — from all of us at Team Poolsuite 🍸"
        );
        window.location.reload();
      } catch (err) {
        this.error = err.message;
      } finally {
        this.walletStatusText = "Login";
      }
    },
  },
};
</script>

<style lang="scss">
body,
html {
  margin: 0;
  padding: 0;
  font-family: Chicago;
  height: 100%;
  width: 100%;
}

body {
  min-width: 800px;
  min-height: 600px;
  overflow: auto;
  -webkit-overflow-scrolling: touch;
}

#app {
  position: relative;
  font-size: 8px;
  background: #000;
}

* {
  box-sizing: border-box;
  cursor: url("./assets/cursor.svg"), auto !important;
  font-smooth: never;
  -webkit-font-smoothing: none;
  -moz-osx-font-smoothing: none;
  -webkit-font-smoothing: antialiased;
}

// Forms

input[type="text"],
input[type="email"],
input[type="tel"],
input[type="password"],
textarea,
select {
  font-family: "Arial";
  font-size: 8px;
  padding: 1em;
  border: 1px solid #000 !important;
  cursor: url("./assets/insert.svg"), auto !important;
  display: block;
  width: 100%;
  margin-bottom: 3px;
  border-radius: 0;
  -webkit-appearance: none;
  &:focus {
    outline: none;
  }
}

*:not(input):not(textarea) {
  user-select: none;
}
* {
  image-rendering: pixelated;
}

@font-face {
  font-family: "Chicago";
  src: url("./assets/chikarego2-webfont.woff2") format("woff2"),
    url("./assets/chikarego2-webfont.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: url("./assets/PixelArial11.woff2") format("woff2"),
    url("./assets/PixelArial11.woff") format("woff");
  font-weight: normal;
  font-style: normal;
}

@font-face {
  font-family: "Arial";
  src: url("./assets/PixelArial11Bold.woff") format("woff");
  font-weight: bold;
  font-style: normal;
}

::selection {
  color: #fff;
  background: #000;
}

h2 {
  font-size: 16px;
  margin: 0;
}

:root {
  --primary: #effbfe;
  --secondary: #fff;
  --app-height: 100%;
}

.global-item {
  touch-action: none;
  * {
    touch-action: pan-y;
  }
}

.global-item {
  border: 1px solid #000000;
  box-shadow: inset 1px 1px 0px 0px rgb(255, 255, 255),
    5px 5px 0px rgba(0, 0, 0, 0.2);
  background: #effbfe;
  display: flex;
  flex-direction: column;
  z-index: 1 !important;
  pointer-events: all;
  transition: background 0.01s ease-in-out;
  &::after {
    height: 12px;
    right: 17px;
    left: 0;
    bottom: 0;
    position: absolute;
    background: url("./assets/graphic_bottom.png");
    pointer-events: none;
    content: " ";
    display: block;
  }

  &.item-recess {
    .drag-header {
      // height: 0;
      // padding: 0;
      z-index: 10;
      .icon {
        top: 5px;
        right: 5px;
        position: absolute;
        z-index: 20;
      }
      .spacer,
      h2 {
        display: none;
      }
    }
  }

  &.hide-graphic {
    .inner-wrapper {
      padding-bottom: 0;
    }
    .handle-br {
      bottom: 5px !important;
      z-index: 1;
    }
    &::after {
      display: none;
    }
  }

  &.hide-drag {
    .inner-wrapper {
      padding-bottom: 0 !important;
    }
    .handle-br {
      display: none !important;
    }
  }
  &:not(.no-drag) {
    .drag-header {
      cursor: url("./assets/grab.svg"), auto !important;
      h2 {
        cursor: url("./assets/grab.svg"), auto !important;
      }
      .spacer {
        cursor: url("./assets/grab.svg"), auto !important;
        i {
          cursor: url("./assets/grab.svg"), auto !important;
        }
      }
    }
  }
  &.mixtape {
    background: #fff !important;
    .drag-header {
      position: absolute;
      .spacer,
      h2 {
        display: none;
      }
      .icon {
        background: #fff;
        z-index: 2;
      }
    }
  }
  .drag-header {
    display: flex;
    align-items: center;
    padding: 5px;
    height: 21px;
    width: 100%;
    user-select: none;
    h2 {
      font-weight: normal;
    }
    .spacer {
      flex: 1;
      margin: 0;
      i {
        display: block;
        width: 100%;
        height: 1px;
        background: #000;
        + i {
          margin-top: 1px;
        }
      }
    }
    .icon + .spacer {
      margin-left: 5px;
    }
    .spacer + h2 {
      margin-left: 5px;
    }
    .icon {
      border: 1px solid #000;
      position: relative;
      width: 11px;
      height: 11px;
      cursor: url("./assets/click.svg") 3 0, auto !important;
      &::after {
        position: absolute;
        top: 0;
        left: 0;
        content: " ";
        width: 9px;
        height: 9px;
        background: url("./assets/close.png") no-repeat center center;
        background-size: 7px auto;
      }
      &:active {
        background: #000;
        &::after {
          filter: invert(100%);
        }
      }
    }
  }

  .inner-wrapper {
    padding: 0 5px 15px 5px;
    position: relative;
    pointer-events: none;
    > div {
      pointer-events: all;
    }
    flex: 1;
    overflow: hidden;
    &.no-border {
      padding-left: 0;
      padding-right: 0;

      .inner-content {
        padding: 0;
        border: none;
      }
    }
  }

  .inner-content {
    font-family: "Arial";
    border: 1px solid #000;
    padding: 5px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: 100%;
    p {
      &:first-of-type {
        margin-top: 0;
      }
      &:last-of-type {
        margin-bottom: 0;
      }
    }
  }

  .handle {
    display: block !important;
    position: absolute;
    width: 8px;
    height: 8px;
    font-size: 1px;
    background: url("./assets/corner.png") no-repeat center center;
    background-size: 7px auto;
    bottom: 3px !important;
    right: 5px !important;
    cursor: url("./assets/resize.svg"), auto !important;
  }
}

// Generic buttons
button,
.button {
  border: 1px solid #000;
  display: block;
  border-radius: 3px;
  width: 100%;
  background: transparent;
  padding: 0.5em 0;
  font-family: "Chicago";
  text-align: center;
  outline: none;
  font-size: 16px;
  color: #000;
  text-decoration: none;
  cursor: url("./assets/click.svg") 3 0, auto !important;
  * {
    cursor: url("./assets/click.svg") 3 0, auto !important;
  }
  &:active {
    background: #000;
    color: #fff;
  }
}

select {
  padding: 0.5em;
  cursor: url("./assets/click.svg") 3 0, auto !important;
}

.main-wrapper {
  height: var(--app-height);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/* width */
.draggable-bounds ::-webkit-scrollbar,
.tribute-container ::-webkit-scrollbar {
  width: 15px;
  cursor: url("./assets/click.svg") 3 0, auto !important;
}

/* Handle */
.draggable-bounds ::-webkit-scrollbar-thumb,
.tribute-container ::-webkit-scrollbar-thumb {
  width: 14px !important;
  height: 40px !important;
  right: 0 !important;
  background: var(--primary) url("./assets/grabber.svg") no-repeat center center !important;
  image-rendering: pixelated;
  border-radius: 0 !important;
  opacity: 1;
  border-top: 1px solid #000;
  border-left: 1px solid #000;
  border-bottom: 1px solid #000;
  cursor: url("./assets/click.svg") 3 0, auto !important;
}

.draggable-bounds ::-webkit-scrollbar-track,
.tribute-container ::-webkit-scrollbar-track {
  width: 16px;
  background: var(--primary) url("./assets/graphic_bottom.png");
  opacity: 1 !important;
  border-left: 1px solid #000;
  padding: 14px 0;
  cursor: url("./assets/click.svg") 3 0, auto !important;
}
</style>

<style lang="scss" scoped>
.capitalize {
  text-transform: capitalize;
  &.nyc {
    text-transform: uppercase;
  }
}
@media (max-width: 800px) {
  .desktop-only {
    display: none;
  }
}

.global-item {
  position: relative;
  &::after {
    right: 0;
    left: 125px;
    @media (max-width: 700px) {
      left: 0;
    }
  }
  &.connect-mode {
    &::after {
      left: 0;
    }
  }
}

.main-wrapper {
  --primary: #f4f4f4;
  --secondary: #f4f4f4;
}

.main-wrapper {
  background: url("./assets/event/bg.png");
  @media (min-width: 700px) {
    &.is-email {
      background: url("./assets/event/full.gif");
      background-size: cover;
      background-position: center center;
    }
  }
}

.content-loaded {
  display: flex;
  align-items: center;
  justify-content: center;
}

.drag-header {
  border-bottom: 1px solid #000;
}

.loaded-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
}

.copyright,
.version {
  text-align: center;
  font-size: 8px;
  font-family: "Arial";
  margin: 0;
  @media (max-width: 700px) {
    display: none;
  }
}

.version {
  color: #3d4c8c;
  a {
    color: currentColor;
    cursor: url("./assets/click.svg") 3 0, auto !important;
  }
}

.loader-intro {
  display: flex;
  .sidebar {
    border: 1px solid #000;
    margin: 4px;
    background: url("./assets/event/sidebar.jpg");
    background-position: 50% 50%;
    background-size: cover;
    width: 123px;
    @media (max-width: 700px) {
      display: none;
    }
  }

  .main-content {
    overflow: hidden;
  }
  .main-content,
  .main-content div {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    height: 100%;
    padding: 2em 0 3em 0;
    @media (max-width: 700px) {
      padding: 2em 1em 3em 1em;
    }
    flex: 1;
  }
  background: #fff;
  width: 540px;
  min-height: 392px;
  position: relative;
  background: url("./assets/event/marble.gif");
  img {
    width: 100%;
    height: auto;
    display: block;
  }

  .logo {
    width: 303px;
    margin: 16px 0 0 0;
    @media (max-width: 700px) {
      width: 193px;
      margin: 0;
    }
    &.simple {
      width: 179px;
      position: relative;
      top: 10px;
      left: 10px;
      @media (max-width: 700px) {
        width: 130px;
        top: 0;
        left: 0;
      }
    }
  }
  button {
    display: inline-block;
    width: auto;
    padding-left: 2em;
    padding-right: 2em;
    padding-top: 4px;
    padding-bottom: 4px;
    white-space: nowrap;
    margin-bottom: 20px;
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }
  .blockers {
    display: flex;
    align-items: center;
    justify-content: center;
    list-style: none;
    margin: 0;
    padding: 0;
    width: 100%;
    flex: 1;
    padding: 12px 0;
    li {
      width: 157px;
      height: 115px;
      margin: 0 9px 0 0;
      border: 1px solid #000;
      position: relative;
      &:last-of-type {
        margin-right: 0;
      }
      &::before {
        content: " ";
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        border: 5px solid rgba(#acacac, 0.7);
      }
      &:first-of-type {
        span {
          background-image: url("./assets/event/step1.gif");
        }
      }
      &:nth-of-type(2) {
        span {
          background-image: url("./assets/event/step2.gif");
        }
      }
      &:last-of-type {
        span {
          background-image: url("./assets/event/step3.gif");
        }
      }
      span {
        width: 100%;
        height: 100%;
        display: block;
        background-size: cover;
        background-position: 50% 50%;
        z-index: 2;
        position: relative;
        opacity: 0;
        &.visible {
          opacity: 1;
        }
      }
    }
  }

  .text-content {
    width: 100%;
    text-align: center;
    font-family: "Arial";
    p {
      margin: 0 0 13px 0;
      @media (max-width: 700px) {
        margin: 0 0 9px 0;
      }
    }
    &.enter-username {
      width: 100%;
      padding: 22px 8px;
      width: auto;
      z-index: 2;
      flex: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
    }
  }

  .select-wrapper-outer {
    display: flex;
    align-items: center;
    padding: 0 2em;
    width: 100%;
    + .select-wrapper-outer {
      margin-top: 1em;
    }
    label {
      font-family: "Chicago";
      font-size: 16px;
      white-space: nowrap;
      flex: 1;
      text-align: left;
    }
  }
}

#guestList {
  z-index: 5;
}

#eventList {
  z-index: 4;
}

#execMemberList {
  z-index: 3;
}

.select-relative {
  position: relative;
  margin-left: 2em;
  z-index: 2;
}

.select-wrapper {
  width: 215px;
  position: relative;
  border: none !important;
  background: #fff;
  border: 1px solid #000 !important;
  // background: none;
  text-align: left;
  padding: 7px;
  &:not(.faux-select) {
    &::after {
      background: url("./assets/down.png") no-repeat center center;
      position: absolute;
      top: 7px;
      right: 7px;
      content: "";
      width: 10px;
      height: 10px;
      // transition: all 0.2s ease-in-out;
    }
    cursor: url("./assets/click.svg") 3 0, auto !important;
    * {
      cursor: url("./assets/click.svg") 3 0, auto !important;
    }
  }
  &.faux-select {
    background: none;
  }
  &.active::after {
    transform: rotate(180deg);
  }
  strong {
    font-size: 16px;
    pointer-events: none;
    font-weight: normal;
    font-family: "Chicago";
  }
  span {
    padding-left: 0.25em;
    font-size: 8px;
    font-family: "Arial";
    position: relative;
    top: 0px;
  }
}

.select-options-scroll {
  // max-height: 85px;
  overflow: hidden;
  position: absolute;
  // margin: -15px -10px 15px -10px;
  width: 100%;
  // top: 0;
  left: 0;
  right: 0;
  border: 1px solid #000;
  border-top: none;
  z-index: 2;
}

.select-options {
  // position: absolute;
  border-top: none;
  list-style: none;
  font-family: Arial;
  overflow: auto;
  font-size: 8px;
  font-weight: normal;
  padding: 0;
  margin: -1px 0;
  background: #fff;
  width: 100%;
  max-height: 117px;
  li {
    padding: 1em;
    display: flex;
    align-items: center;
    cursor: url("./assets/click.svg") 3 0, auto !important;
    + li {
      border-top: 1px solid #000;
    }
    &:hover {
      background: #f2f2f2;
      span {
        background: #f2f2f2 url("./assets/new-hover.png") no-repeat center
          center;
      }
    }

    span {
      background: var(--primary) url("./assets/new.png") no-repeat center center;
      padding: 2px 5px;
      margin-left: 5px;
      text-transform: uppercase;
      font-size: 6px;
      animation: blink-animation 1s steps(5, start) infinite;
      cursor: url("./assets/click.svg") 3 0, auto !important;
      width: 27px;
    }
  }
}

/* Handle */
.select-options-scroll ::-webkit-scrollbar-thumb {
  background-color: #f2f2f2 !important;
}

.select-options-scroll ::-webkit-scrollbar-track {
  background-color: #f2f2f2 !important;
}

.email-wrapper,
.signup-wrapper {
  .inner-content {
    overflow: visible;
  }
  &:not(.visible) {
    opacity: 0;
    visibility: hidden;
  }
  &::after {
    left: 0;
  }
}

.email-wrapper {
  width: 420px;
  height: 340px;
  .outer-scroll > div {
    margin: 0;
    // padding-top: 136px;
    overflow: auto;
    -webkit-overflow-scrolling: touch;
    height: calc(100% + 2px);
    margin: -1px 0;
  }

  .group {
    padding: 1.5em;
    + .group {
      border-top: 1px dotted #000;
    }
  }

  .outer-scroll {
    overflow: hidden;
    height: calc(100% - 104px);
    background: #fff;
    p {
      line-height: 1.7;
      .is-bold {
        font-family: "Chicago";
        font-size: 16px;
        margin: 0;
        line-height: 1;
      }
      a {
        color: #000;
        cursor: url("./assets/click.svg") 3 0, auto !important;
      }
    }
    img {
      width: 100%;
      display: block;
      height: auto;
    }
  }
}

.intro-text {
  margin-top: 10px;
  margin-bottom: -10px;
  p {
    font-size: 9px;
    font-family: "Arial";
    font-weight: normal;
    line-height: 1.7;
    padding: 0 25px;
    .is-bold {
      font-family: "Chicago";
      font-size: 16px;
      margin: 0;
      line-height: 1;
    }
    a {
      color: #000;
      cursor: url("./assets/click.svg") 3 0, auto !important;
    }
  }
  text-align: center;
}

.signup-wrapper {
  width: 220px;
  margin-left: 45px;
  position: relative;
  &::after {
    right: 0;
  }

  .select-relative {
    margin: 0;
    margin-bottom: 3px;
    .select-wrapper {
      width: 100%;
    }
  }

  form,
  .form {
    padding: 10px 20px 26px 20px; // top right bottom left
  }

  p {
    font-size: 8px;
    font-family: "Arial";
    font-weight: normal;
    margin: 10px 0 10px 0 !important;
    line-height: 1.7;
  }

  button {
    line-height: 29px;
    padding-top: 0;
    padding-bottom: 0;
    &:disabled {
      pointer-events: none;
      opacity: 0.5;
    }
  }

  .form {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 100%;
  }

  .form p {
    text-align: center;
  }
}

.sender-details {
  padding: 1.5em;
  border-bottom: 1px solid #000;
  p {
    margin: 0;
    font-family: Arial;
    overflow: hidden;
    + p {
      margin-top: 5px;
    }
    strong {
      font-size: 16px;
      font-weight: normal;
      font-family: "Chicago";
      width: 70px;
    }
    span {
      float: right;
      width: calc(100% - 70px);
      display: inline-block;
      line-height: 2;
      position: relative;
      top: 1px;
      a {
        color: currentColor;
        cursor: url("./assets/click.svg") 3 0, auto !important;
      }
    }
  }
}

.inner-content {
  padding: 0;
  border: 0;
  overflow: hidden;
  background: url("./assets/event/marble.gif");
}

.flashing-dots {
  span {
    animation: blink-animation 1s steps(2, start) infinite;
    display: inline-block;
    // margin-left: -3px;
  }
  span:nth-child(1) {
    margin-left: -3px;
  }
  span:nth-child(2) {
    animation-delay: 0.2s;
  }
  span:nth-child(3) {
    animation-delay: 0.3s;
  }
}

@keyframes blink-animation {
  to {
    visibility: hidden;
  }
}

@media (max-width: 700px) {
  .background-wrapper {
    // height: 100vh;
    display: flex;
  }

  .loader-intro {
    width: 300px;
    min-height: 330px;
    .blockers {
      // top: 83px;
      // left: 11px;
      padding: 0;
      li {
        margin: 0 5px 0 0;
        &::before {
          border-width: 3px;
        }
      }
      li {
        width: 86px;
        height: 60px;
      }
    }
    .text-content {
      // bottom: 36px;
      &.enter-username {
        // left: 10px;
        // right: 10px;
        bottom: 50px;
        padding: 11px 0;
        width: 100%;
        flex: initial;
        .select-wrapper-outer {
          // display: block;
          text-align: left;
          padding: 0;
        }
        .select-relative {
          margin-left: 0;
          // margin-top: 0.75em;
        }
        .select-wrapper {
          width: 152px;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          padding-right: 24px;
        }
        .select-options li {
          font-size: 6px;
        }
      }
    }
    button {
      margin-bottom: 0;
      height: 23px;
      line-height: 5px;
    }
  }

  .content-loaded {
    display: block;
  }

  .loaded-wrapper {
    flex-direction: column-reverse;
    padding: 2em;
    height: var(--app-height);
    overflow: auto;
  }

  .fix-width {
    width: 302px;
    margin: 2em auto !important;
  }

  .content-loaded {
    // padding: 2em;
    .global-item {
      margin: auto;
    }
    .email-wrapper {
      height: auto;
      width: 100%;
      min-height: 0;
      flex: 1;
    }
    .signup-wrapper {
      margin-bottom: 2em;
      width: 100%;
    }
  }
}
</style>

<style>
body {
  min-width: 0;
  min-height: 0;
}

#app {
  background: #000 !important;
}

form p a,
.form p a,
form p span.action,
a img {
  color: #000;
  cursor: url("./assets/click.svg") 3 0, auto !important;
  text-decoration: underline;
}

form p strong {
  font-family: Arial;
  font-weight: bold;
  font-size: 8px;
}

p.error,
p.error a {
  color: #e00;
  font-family: Arial;
  font-size: 9px;
}

.group ul {
  padding-left: 10px;
  margin-bottom: 0;
}

.group li + li {
  margin-top: 5px;
  line-height: 1.7;
}
</style>

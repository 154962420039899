import "regenerator-runtime/runtime.js";
import { createApp } from "vue";
import App from "./App.vue";
import "./index.css";
import VueCookie from "vue-cookies";

const app = createApp(App);
app.use(VueCookie);

app.mount("#app");
